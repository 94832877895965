import {
  setSessionsLoading,
  gotSessions,
  setSessionLoading,
  gotSession,
} from '../redux/actionsSessions.js';

import firebase from '../../_snowpack/pkg/firebase/app.js';
import '../../_snowpack/pkg/firebase/firestore.js';

import { store } from '../redux/store.js';

class SessionsRepository {
  constructor(repository){
    this.repository = repository;

    store.subscribe(this._stateChanged.bind(this));
    this._stateChanged();

    this.urlBase = "https://api.quicklypro.it";
    
    this.listenerRealtimeSessions = undefined;
    this.listenerRealtimeSession = undefined;

    this.user = undefined;
  }

  async _stateChanged(state = store.getState()){
    this.user = state.auth.user;
  }

  async getRealtimeSessions(recordId){
    if(this.listenerRealtimeSessions){
      this.listenerRealtimeSessions();
      this.listenerRealtimeSessions = undefined;
    }
    store.dispatch(setSessionsLoading(true));
    this.listenerRealtimeSessions = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).collection('records').doc(recordId).collection('sessions').orderBy("initDate", "asc").onSnapshot(snapshot => {
      const sessions = [];
      snapshot.forEach(doc => {
        sessions.push({
          ...doc.data(),
          id: doc.id,
          initDate: doc.data().initDate.toDate(),
        });
      });
      store.dispatch(gotSessions(sessions));
      store.dispatch(setSessionsLoading(false));
    }, err => {
      console.log(err);
      store.dispatch(gotSessions(undefined));
      store.dispatch(setSessionsLoading(false));
    });
  }

  async getRealtimeSession(recordId, sessionId){
    if(this.listenerRealtimeSession || recordId === undefined || sessionId === undefined){
      this.listenerRealtimeSession();
      this.listenerRealtimeSession = undefined;
    }
    store.dispatch(setSessionLoading(true));
    this.listenerRealtimeSession = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).collection('records').doc(recordId).collection('sessions').doc(sessionId).onSnapshot(async doc => {
      store.dispatch(gotSession(doc.exists ? { ...doc.data(), id: sessionId, initDate: doc.data().initDate.toDate() } : undefined));
      store.dispatch(setSessionLoading(false));
    }, err => {
      console.log(err);
      store.dispatch(gotSession(undefined));
      store.dispatch(setSessionLoading(false));
    });
  }

  async createSession({ recordId, data }={}){
    return this.repository.saveEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/records/${ recordId }/sessions/`, data });
  }

  async deleteSession({ recordId, sessionId }={}){
    return this.repository.deleteEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/records/${ recordId }/sessions/${ sessionId }` });
  }
}

export default SessionsRepository;