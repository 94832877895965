export const SET_SESSIONS_LOADING = 'SET_SESSIONS_LOADING';
export const GOT_SESSIONS = 'GOT_SESSIONS';
export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const GOT_SESSION = 'GOT_SESSION';

export const setSessionsLoading = isLoading => {
  return {
    type: SET_SESSIONS_LOADING,
    isLoading
  };
};

export const gotSessions = sessions => {
  return {
    type: GOT_SESSIONS,
    sessions
  };
};

export const setSessionLoading = isLoading => {
  return {
    type: SET_SESSION_LOADING,
    isLoading
  };
};

export const gotSession = session => {
  return {
    type: GOT_SESSION,
    session
  };
};