export const SET_RECORDS_LOADING = 'SET_RECORDS_LOADING';
export const GOT_RECORDS = 'GOT_RECORDS';
export const SET_RECORD_LOADING = 'SET_RECORD_LOADING';
export const GOT_RECORD = 'GOT_RECORD';
export const SET_RECORD_SENSITIVE_LOADING = 'SET_RECORD_SENSITIVE_LOADING';
export const GOT_RECORD_SENSITIVE = 'GOT_RECORD_SENSITIVE';
export const SET_USER_RECORDS_LOADING = 'SET_USER_RECORDS_LOADING';
export const GOT_USER_RECORDS = 'GOT_USER_RECORDS';

export const setRecordsLoading = isLoading => {
  return {
    type: SET_RECORDS_LOADING,
    isLoading
  };
};

export const gotRecords = records => {
  return {
    type: GOT_RECORDS,
    records
  };
};

export const setRecordLoading = isLoading => {
  return {
    type: SET_RECORD_LOADING,
    isLoading
  };
};

export const gotRecord = (record, e3group) => {
  return {
    type: GOT_RECORD,
    record,
    e3group
  };
};

export const setRecordSensitiveLoading = isLoading => {
  return {
    type: SET_RECORD_SENSITIVE_LOADING,
    isLoading
  };
};

export const gotRecordSensitive = (recordSensitive) => {
  return {
    type: GOT_RECORD_SENSITIVE,
    recordSensitive
  };
};

export const setUserRecordsLoading = isLoading => {
  return {
    type: SET_USER_RECORDS_LOADING,
    isLoading
  };
};

export const gotUserRecords = records => {
  return {
    type: GOT_USER_RECORDS,
    records
  };
};