import { html, css, LitElement } from '../../_snowpack/pkg/lit-element.js';

import { SharedStyles } from '../extras/shared-styles.js';

import { repository } from '../network/repository.js';

import firebase from '../../_snowpack/pkg/firebase/app.js';
import '../../_snowpack/pkg/firebase/auth.js';

//import '/dist/components/component-splash.js';
import '../../_snowpack/pkg/@material/mwc-button.js';
import '../../_snowpack/pkg/@material/mwc-textfield.js';
import '../../_snowpack/pkg/@material/mwc-snackbar.js';

class ComponentAuth extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
        }

        .sign-in-form {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 280px;
          max-width: calc(100vw - 1rem);
          padding: 2rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          border-radius: 12px;
          background: #fff;
          font-family: 'Montserrat', sans-serif;
        }

        .sign-in-form h1 {
          margin-top: 0;
          margin-bottom: 0;
        }

        .sign-in-form img {
          width: 100%;
          margin-bottom: 1rem;
        }

        .sign-in-form mwc-textfield {
          width: 100%;
          margin-bottom: 1rem;
        }

        .sign-in-form mwc-button {
          --mdc-theme-primary: #fff;
          --mdc-theme-on-primary: #000;
        }

        .sign-in-form .sign-in-button {
          --mdc-theme-primary: var(--app-accent-color);
          --mdc-theme-on-primary: #fff;
        }

        .button-anon {
          background-color: #fcfcfc;
          color: #1c1c1c;
          text-transform: none;
        }

        .button-sign {
          margin-top: 1rem;
          text-transform: none;
        }

        .errors {
          margin-top: 1rem;
          color: tomato;
          display: flex;
          flex-direction: column;
          row-gap: 0.5rem;
        }

        .errors span {
          text-decoration: underline;
          color: var(--app-primary-text-color);
          cursor: pointer;
        }

        component-splash {
          background-color: rgba(0,0,0,0.3);
          position: absolute;
          top: 0; right: 0; bottom: 0; left: 0;
          z-index: 9;
        }
      `
    ];
  }

  render() {
    return html`
      <div class="sign-in-form">
        <h1>QuicklyPRO</h1>
        <small>Beta</small>
        <img src="/images/doctor.webp" />
        <mwc-textfield label="Email" type="email" name="email" required @keyup=${this._checkEnter}></mwc-textfield>
        <mwc-textfield label="Password" type="password" name="pwd" required @keyup=${this._checkEnter} minlength="6"></mwc-textfield>
        <mwc-button class="sign-in-button" @click=${this._signIn} unelevated>Sign in</mwc-button>
        ${!this._isSigningIn && this._hasErrors !== undefined
          ? html`
            <div class="errors">
              ${this._hasErrors.message}
              ${ this._hasErrors.code === "auth/wrong-password" || this._hasErrors.code === "auth/too-many-requests"
                ? html`
                  <span @click=${this._forgotPassword}>${this._hasErrors.code === "auth/wrong-password" ? "Forgot" : ""}${this._hasErrors.code === "auth/too-many-requests" ? "Reset" : ""} password?</span>
                ` : html``
              }
            </div>
          ` : html`
        `}
      </div>
      <mwc-snackbar id="snackReset" timeoutMs="10000" labelText="Check your inbox for the reset password email">
        <mwc-button slot="action">Ok</mwc-button>
      </mwc-snackbar>
    `
  }

  static get properties() {
    return {
      _isSigningIn: { type: Boolean },
      _hasErrors: { type: Object },
    };
  }

  constructor(){
    super();
    this._isSigningIn = false;
    this._hasErrors = undefined;
  }

  async _signIn(){
    this._hasErrors = undefined;
    const inputEmail = this.shadowRoot.querySelector("mwc-textfield[name=email]");
    const inputPwd = this.shadowRoot.querySelector("mwc-textfield[name=pwd]");
    if(!inputEmail.reportValidity() || !inputPwd.reportValidity())
      return;
    this._isSigningIn = true;
    try {
      await repository.firebase.auth().signInWithEmailAndPassword(inputEmail.value, inputPwd.value);
      //await new Promise(r => setTimeout(r, 500000));
      this._isSigningIn = false;
    } catch(err) {
      switch(err.code){
        case "auth/wrong-password":
          this._hasErrors = { message: "Wrong email/password", code: err.code };
          break;
        case "auth/user-not-found":
          this._hasErrors = { message: "User not found", code: err.code };
          break;
        case "auth/too-many-requests":
          this._hasErrors = { message: "Too many requests, reset your password or try again later", code: err.code };
          break;
        default:
          this._hasErrors = { message: "Unable to login", code: "unknown" };
          console.log(err);
      }
      this._isSigningIn = false;
    }
  }

  /*async _signInAnonymously(){
    this._isSigningIn = true;
    this._hasErrors = undefined;
    try {
      await repository.firebase.auth().signInAnonymously();
      this._isSigningIn = false;
    } catch(err) {
      console.log(err);
      this._hasErrors = { message: "Unable to login", code: "unknown" };
      this._isSigningIn = false;
    }
  }*/

  /*async _signInGoogle(){
    this._isSigningIn = true;
    this._hasErrors = undefined;
    try {
      const result = await repository.firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());
      //const token = result.credential.accessToken;
      //const user = result.user;
      this._isSigningIn = false;
    } catch(err) {
      console.log(err);
      this._hasErrors = { message: "Unable to login", code: "unknown" };
      this._isSigningIn = false;
    }
    
  }*/

  _checkEnter(e) {
    if (e.keyCode === 13)
      this._signIn();
  }

  _forgotPassword(){
    var actionCodeSettings = {
      url: window.location.protocol + "//" + window.location.host,
      handleCodeInApp: false
    };
    repository.firebase.auth().sendPasswordResetEmail(this.shadowRoot.querySelector("mwc-textfield[name=email]").value, actionCodeSettings);
    this.shadowRoot.querySelector("#snackReset").show();
  }
}

window.customElements.define('component-auth', ComponentAuth);