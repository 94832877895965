import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import {
  SET_LANGUAGE,
} from './actionsCore.js';

const INITIAL_STATE = {
  version: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_VERSION || "0.0.0"/*"1.1.0"*/,
  languages: { 'en': { label: 'Eng' }, 'it': { label: 'Ita' } },
  language: 'en'
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state
  }
};