import { css } from '../../_snowpack/pkg/lit-element.js';

export const SharedStyles = css`

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Comfortaa', cursive;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  a.underline {
    text-decoration: underline;
  }

  mwc-fab {
    --mdc-theme-secondary: var(--app-accent-color);
    --mdc-theme-on-secondary: #fff;
  }

  mwc-fab[disabled] {
    --mdc-fab-box-shadow: none;
    --mdc-theme-secondary: #d65c6a;
  }

  mwc-fab.fab-alt {
    --mdc-theme-secondary: var(--app-primary-light-color);
    --mdc-theme-on-secondary: #fff;
  }

  mwc-textfield {
    --mdc-theme-primary: var(--app-primary-text-color);
    --mdc-text-field-ink-color: var(--app-primary-text-color);
    --mdc-text-field-label-ink-color: var(--app-primary-text-color);
    --mdc-text-field-fill-color: #fff;
    --mdc-text-field-disabled-ink-color: var(--app-primary-text-color);
    --mdc-text-field-disabled-label-ink-color: var(--app-primary-text-color);
    --mdc-text-field-disabled-fill-color: var(--app-background-color);
    --mdc-text-field-outlined-idle-border-color: var(--app-primary-text-color);
    --mdc-text-field-outlined-hover-border-color: var(--app-primary-text-color);
    --mdc-text-field-outlined-disabled-border-color: var(--app-primary-text-color);
    --mdc-theme-error: red;
  }

  mwc-textarea {
    
  }

  mwc-button {
    --mdc-theme-primary: var(--app-accent-color);
    --mdc-theme-on-primary: #fff;
  }

  mwc-slider {
  }

  mwc-dialog {
    --mdc-theme-surface: #fff;
    --mdc-dialog-content-ink-color: var(--app-primary-text-color);
  }

  mwc-menu {
  }

  mwc-select {
    --mdc-theme-primary: var(--app-primary-text-color);
  }

  mwc-list-item {
    --mdc-theme-primary: var(--app-primary-text-color);
  }

  mwc-switch {
    --mdc-theme-secondary: var(--app-accent-color);
  }

  vaadin-date-time-picker {
  }

  section {
    margin-top: 3rem;
  }

  .centered-content {
    max-width: 1200px;
    margin: 0px auto;
    padding: 0px 2rem;
    position: relative;
  }

  .card {
    background-color: var(--app-background-light-color);
    padding: 1.5rem 2rem;
    /*box-shadow: 0 0 32px 0px rgb(0 0 0 / 15%);*/
    box-shadow: 6px 7px 20px 0px #50899199;
    border-radius: 12px;
  }

  .user-pic {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    object-fit: cover;
    box-shadow: 0 0 8px 0px rgb(0 0 0 / 15%);
    background-color: #fff;
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  * {
    box-sizing: border-box;
  }

  *::-webkit-scrollbar-track { -webkit-box-shadow:inset 0 0 6px rgba(255,255,255,0.3); box-shadow: inset 0 0 6px rgba(255,255,255,0.3); background-color: rgb(255, 255, 255); }
  *::-webkit-scrollbar { width:6px;}
  *::-webkit-scrollbar-thumb { background-color:var(--app-accent-color); }
`;
