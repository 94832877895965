import {
  SET_SESSIONS_LOADING,
  GOT_SESSIONS,
  SET_SESSION_LOADING,
  GOT_SESSION,
} from './actionsSessions.js';

const INITIAL_STATE = {
  isSessionsLoading: true,
  sessions: undefined,
  isSessionLoading: true,
  session: undefined,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SESSIONS_LOADING:
      return {
        ...state,
        isSessionsLoading: action.isLoading
      };
    case GOT_SESSIONS:
      return {
        ...state,
        sessions: action.sessions
      };
    case SET_SESSION_LOADING:
      return {
        ...state,
        isSessionLoading: action.isLoading
      };
    case GOT_SESSION:
      return {
        ...state,
        session: action.session
      };
    default:
      return state
  }
};