import {
  setLoading,
  gotLoggedUser,
  loggedOut,
  gotOrg,
  gotUsers,
  gotUsersSensitive,
  setE3KitStatus
} from '../redux/actionsAuth.js';

import firebase from '../../_snowpack/pkg/firebase/app.js';
import '../../_snowpack/pkg/firebase/firestore.js';

import { store } from '../redux/store.js';

//const EThree = window.E3kit.EThree;

class AuthRepository {
  constructor(repository){
    this.repository = repository;

    store.subscribe(this._stateChanged.bind(this));
    this._stateChanged();

    this.urlBase = "https://api.quicklypro.it";

    store.dispatch(setLoading(true));
    
    /*** Data ***/
    this.firebaseUser = undefined;
    /*** Listeners ***/
    this.listenerRealtimeUserDetails = undefined;
    this.listenerRealtimeOrg = undefined;
    this.listenerRealtimeDetails = undefined;
    this.listenerRealtimeUsers = undefined;
    this.listenerRealtimeUsersSensitive = undefined;

    this.e3KitToken = undefined;
    this.e3KitClient = undefined;
  }

  async _stateChanged(state = store.getState()){
  }

  async authStateChanged(user){
    store.dispatch(setLoading(true));
    if(user){
      this.firebaseUser = user;
      await this.refreshToken();
      try {
        this.startTokenRefreshInterval();
        this.firebaseUser.id = this.firebaseUser.uid;
        this.getRealtimeUserDetails();
      } catch(e) {
        console.log(e);
        debugger;
        this.firebaseUser.id = this.firebaseUser.uid;
        store.dispatch(loggedIn(this.firebaseUser));
        this.getRealtimeOrg();
        //store.dispatch(setLoading(false));
      }
    }else{
      store.dispatch(loggedOut());
      store.dispatch(setLoading(false));
    }
  }
  
  async refreshToken() {
    const parsedToken = await this.firebaseUser.getIdTokenResult(true);
    this.firebaseUser.claims = { isAdmin: parsedToken.claims.isAdmin || false, t: parsedToken.claims.t, org: parsedToken.claims.org };
    this.repository.accessToken = parsedToken.token;
  }

  startTokenRefreshInterval(){
    setInterval(this.refreshToken.bind(this), 3000000);
  }

  async signOut(){
    if(this.e3KitClient)
      await this.e3KitClient.cleanup();
    window.localStorage.clear();
    this.repository.firebase.auth().signOut();
    await this.repository.firestore.terminate();
    await this.repository.firestore.clearPersistence();
    window.location.reload();
  }

  async getRealtimeUserDetails(){
    if(this.listenerRealtimeUserDetails){
      this.listenerRealtimeUserDetails();
      this.listenerRealtimeUserDetails = undefined;
    }
    store.dispatch(setLoading(true));
    this.listenerRealtimeUserDetails = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).collection('users').doc(this.firebaseUser.uid).collection('sensitive').doc(this.firebaseUser.uid).onSnapshot(doc => {
      if(doc.exists){
        store.dispatch(gotLoggedUser({ ...this.firebaseUser, sensitive: doc.data() }));
        if(!this.listenerRealtimeOrg)
          this.getRealtimeOrg();
      }else{
        store.dispatch(gotLoggedUser({ ...this.firebaseUser, isGuest: true }));
      }
    }, err => {
      store.dispatch(gotLoggedUser({ ...this.firebaseUser, isGuest: true }));
      store.dispatch(setLoading(false));
    });
  }

  async getRealtimeOrg(){
    if(this.listenerRealtimeOrg){
      this.listenerRealtimeOrg();
      this.listenerRealtimeOrg = undefined;
    }
    this.listenerRealtimeOrg = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).onSnapshot(doc => {
      this.handleRealtimeOrgSnapshot(doc.exists ? { ...doc.data(), id: doc.id } : undefined);
    });
  }

  async handleRealtimeOrgSnapshot(orgData){
    if(!orgData){
      if(this.listenerRealtimeOrg){
        this.listenerRealtimeOrg();
        this.listenerRealtimeOrg = undefined;
      }
      debugger; //nope
      return;
    }
    store.dispatch(gotOrg(orgData));
    store.dispatch(setLoading(false));
    //await this._loadTokens(); //TODO Virgil not enabled
    if(!this.listenerRealtimeUsers)
      this.getRealtimeUsers();
    if(!this.listenerRealtimeUsersSensitive)
      this.getRealtimeUsersSensitive();
  }

  async _loadTokens() {
    if(this.e3KitClient)
      return;
    const data = await this.repository.saveEntity({ urlBase: this.urlBase, urlPath: `/orgs/${ this.repository.activeOrg }/actions/getTokens`, data: { } });
    this.e3KitToken = data.virgilToken;
    await this._initVirgil();
  }

  async _initVirgil(){
    this.e3KitClient = await EThree.initialize(this.getVirgilToken.bind(this));
    const hasLocalPrivateKey = await this.e3KitClient.hasLocalPrivateKey();
    if(hasLocalPrivateKey) {
      store.dispatch(setE3KitStatus('success'));
      return;
    }
    store.dispatch(setE3KitStatus('no-local-key'));
    //await this._virgilRegister();
    await this.virgilRecoverKey(this.repository.activeOrg+this.firebaseUser.uid);
    //await this.virgilReset();
    //await this.e3KitClient.unregister();
    //console.log("Unregistered");



    //await this.e3KitClient.unregister();
    /*const hasLocalPrivateKey = await this.e3KitClient.hasLocalPrivateKey();
    try { 
      await this.e3KitClient.register(); 
    } catch(err) { 
      debugger;
      if(err.name === "IdentityAlreadyExistsError") {
        try {
          await this.e3KitClient.restorePrivateKey();
        } catch(err) {
          console.log(err);
        }
        return;
      }
      console.log(ignored) 
    };*/
  }

  async _virgilRegister() {
    try { 
      await this.e3KitClient.register(); 
      store.dispatch(setE3KitStatus('registered'));
      await this._virgilBackupKey();
    } catch(err) {
      switch(err.name) {
        case 'IdentityAlreadyExistsError':
          //store.dispatch(setE3KitStatus('ask-key-password')); //TODO
          await this.virgilRecoverKey(this.repository.activeOrg+this.firebaseUser.uid);
          return;
        default:
          console.log(err);
      }
    }
  }

  async virgilRecoverKey(password) {
    try {
      //debugger;
      store.dispatch(setE3KitStatus('recovering-key'));
      await this.e3KitClient.restorePrivateKey(password);
      store.dispatch(setE3KitStatus('success'));
    } catch(err) {
      switch(err.name) {
        case 'PrivateKeyNoBackupError':
          store.dispatch(setE3KitStatus('no-backup'));
          //await this.virgilReset();
          await this._virgilRegister();
          break;
        case 'WrongKeyknoxPasswordError':
          store.dispatch(setE3KitStatus('wrong-key-password'));
          break;
        default:
          console.log(err);
      }
    }
  }

  async virgilReset() {
    console.log("Resetting");
    await this.e3KitClient.rotatePrivateKey();
    this._virgilBackupKey();
  }

  async _virgilBackupKey() {
    //store.dispatch(setE3KitStatus('confirm-key-password')); //TODO
    this.virgilConfirmKeyPassword(this.repository.activeOrg+this.firebaseUser.uid);
  }

  async virgilConfirmKeyPassword(password) {
    await this.e3KitClient.backupPrivateKey(password);
    store.dispatch(setE3KitStatus('success'));
  }

  getVirgilToken(){
    return this.e3KitToken;
  }

  async getRealtimeUsers(){
    if(this.listenerRealtimeUsers){
      this.listenerRealtimeUsers();
      this.listenerRealtimeUsers = undefined;
    }
    this.listenerRealtimeUsers = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).collection('users').onSnapshot(snapshot => {
      const users = [];
      snapshot.forEach(userDoc => {
        users.push({
          ...userDoc.data(),
          id: userDoc.id
        });
      });
      store.dispatch(gotUsers(users));
    });
  }

  async getRealtimeUsersSensitive(){
    if(this.listenerRealtimeUsersSensitive){
      this.listenerRealtimeUsersSensitive();
      this.listenerRealtimeUsersSensitive = undefined;
    }
    this.listenerRealtimeUsersSensitive = this.repository.firestore.collectionGroup('sensitive').where("orgId", "==", this.repository.activeOrg).onSnapshot(snapshot => {
      store.dispatch(gotUsersSensitive([]));
      /*const usersSensitive = [];
      snapshot.forEach(userSensitiveDoc => {
        usersSensitive.push({
          ...userSensitiveDoc.data(),
          id: userSensitiveDoc.id
        });
      });
      store.dispatch(gotUsersSensitive(usersSensitive));*/
    }, err => {
      console.log(err);
      store.dispatch(gotUsersSensitive([]));
    });
  }

  async createUser({ alias, type, email, needNewPass, password }={}){
    return this.repository.saveEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/users/`, data: { alias, type, email, needNewPass, password } });
  }

  async removeUser(id){
    return this.repository.deleteEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/users/${id}` });
  }

  async updateUser({ id, alias, photoUrl, needNewPass }={}){
    return this.repository.updateEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/users/${id}`, data: { alias, photoUrl, needNewPass } });
  }

  async resetPassword(password) {
    return this.repository.saveEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/users/${this.firebaseUser.uid}/resetPassword`, data: { password } });
  }

  changeProfilePicture(userId){
    const input = document.createElement('input');
    input.type = 'file';
    input.value = '';
    input.click();
    input.onchange = function(e) {
      var file = input.files[0];
      if(file === undefined)
        return;
      switch(file.type){
        case 'image/jpeg':
          break;
        case 'image/jpg':
          break;
        case 'image/png':
          break;
        default:
          if(!file.type.startsWith('application/')){
            return;
          }
      }
      if(!file){
        alert('File is not set');
        return;
      }

      let contentType = file.type;
      switch(file.type){
        case 'image/jpeg':
          break;
        case 'image/jpg':
          break;
        case 'image/png':
          break;
        default:
          alert('File not supported');
          return;
      } 

      const storageRef = this.repository.storage.ref(`orgs/${this.repository.activeOrg}/user-pics/${userId}`);

      const metadata = {
        cacheControl: 'public, max-age=31536000',
        contentType: contentType,
      };

      const uploadTask = storageRef.put(file, metadata);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //onProgress(progress);
        }.bind(this), function(error) {
          const err = new Error(error.code);
          err.verbose = error.verbose;
          //onError(err);
        }.bind(this), async function() {
          const fileUrl = await uploadTask.snapshot.ref.getDownloadURL();
          try{
            let mediaLocation = uploadTask.snapshot.ref.fullPath;
            await this.updateUser({ id: userId, photoUrl: fileUrl });
            //onSuccess();
          }catch(err){
            //onError(err);
          }
        }.bind(this)
      );
    }.bind(this);
  }
}

export default AuthRepository;