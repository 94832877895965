import { r as redux } from '../common/redux-811c3676.js';

function createCommonjsModule(fn, basedir, module) {
	return module = {
		path: basedir,
		exports: {},
		require: function (path, base) {
			return commonjsRequire(path, (base === undefined || base === null) ? module.path : base);
		}
	}, fn(module, module.exports), module.exports;
}

function commonjsRequire () {
	throw new Error('Dynamic requires are not currently supported by @rollup/plugin-commonjs');
}

var objectKeys = Object.keys || function (obj) {
  var keys = [];

  for (var key in obj) {
    if ({}.hasOwnProperty.call(obj, key)) keys.push(key);
  }

  return keys;
};

function assign(obj, newKey, newValue) {
  var keys = objectKeys(obj);
  var copy = {};

  for (var i = 0, l = keys.length; i < l; i++) {
    var key = keys[i];
    copy[key] = obj[key];
  }

  copy[newKey] = newValue;
  return copy;
}

var assign_1 = assign;

var logOnly = createCommonjsModule(function (module, exports) {



var compose = redux.compose;

function enhancer() {
  var config = arguments[0] || {};
  config.features = {
    pause: true,
    export: true,
    test: true
  };
  config.type = 'redux';
  if (config.autoPause === undefined) config.autoPause = true;
  if (config.latency === undefined) config.latency = 500;
  return function (createStore) {
    return function (reducer, preloadedState, enhancer) {
      var store = createStore(reducer, preloadedState, enhancer);
      var origDispatch = store.dispatch;

      var devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect(config);

      devTools.init(store.getState());

      var dispatch = function (action) {
        var r = origDispatch(action);
        devTools.send(action, store.getState());
        return r;
      };

      if (Object.assign) return Object.assign(store, {
        dispatch: dispatch
      });
      return assign_1(store, 'dispatch', dispatch);
    };
  };
}

function composeWithEnhancer(config) {
  return function () {
    return compose(compose.apply(null, arguments), enhancer(config));
  };
}

exports.__esModule = true;

exports.composeWithDevTools = function () {
  if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    if (arguments.length === 0) return enhancer();
    if (typeof arguments[0] === 'object') return composeWithEnhancer(arguments[0]);
    return composeWithEnhancer().apply(null, arguments);
  }

  if (arguments.length === 0) return undefined;
  if (typeof arguments[0] === 'object') return compose;
  return compose.apply(null, arguments);
};

exports.devToolsEnhancer = typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ ? enhancer : function () {
  return function (noop) {
    return noop;
  };
};
});

var logOnlyInProduction = createCommonjsModule(function (module, exports) {

var compose = redux.compose;



exports.__esModule = true;
exports.composeWithDevTools =  logOnly.composeWithDevTools ;
exports.devToolsEnhancer =  logOnly.devToolsEnhancer ;
});

var devToolsEnhancer = logOnlyInProduction.devToolsEnhancer;
export { devToolsEnhancer };
