import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import {
  setLanguage
} from '../redux/actionsCore.js';

import AuthRepository from './repositoryAuth.js';
import RecordsRepository from './repositoryRecords.js';
import SessionsRepository from './repositorySessions.js';

import { registerTranslateConfig } from "../../_snowpack/pkg/@appnest/lit-translate.js";
import { use } from "../../_snowpack/pkg/@appnest/lit-translate.js";
//import * as humanizeDuration from 'humanize-duration';

import { store } from '../redux/store.js';

import firebase from '../../_snowpack/pkg/firebase/app.js';
import '../../_snowpack/pkg/firebase/performance.js';
import '../../_snowpack/pkg/firebase/auth.js';
import '../../_snowpack/pkg/firebase/firestore.js';
import '../../_snowpack/pkg/firebase/storage.js';
import '../../_snowpack/pkg/firebase/remote-config.js';

import * as Sentry from "../../_snowpack/pkg/@sentry/browser.js";
import { Integrations } from "../../_snowpack/pkg/@sentry/tracing.js";

class Repository {
  constructor(){
    /*** Repositories ***/
    this.repositoryAuth = new AuthRepository(this);
    this.repositoryRecords = new RecordsRepository(this);
    this.repositorySessions = new SessionsRepository(this);
    /*** Network ***/
    this.defaultContentType = "application/json";
    this.accessToken = undefined;
    this.firebase = undefined;
    this.perf = undefined;
    this.firestore = undefined;
    this.storage = undefined;

    this.urlBase = "https://api.quicklypro.it";
    this.activeOrg = 'Demo-z0izd'; 
    //this.activeOrg = 'Base-58m23';

    this.branding = { type: 'industrial' };

    /*** State ***/
    this.languages = [];
    this.language = 'en';

    this._init();
  }

  async _stateChanged(state = store.getState()){
    this.languages = state.core.languages;
    this.language = state.core.language;
  }

  async _init() {
    this._initErrorReporting();

    this._initFirebase();

    store.subscribe(this._stateChanged.bind(this));
    this._stateChanged();

    this.initLang();
  }

  async _initErrorReporting() {
    if(__SNOWPACK_ENV__.NODE_ENV === 'development' || true)
      return;
    Sentry.init({
      release: "lab101-quicklypro@" + __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_VERSION,
      dsn: "https://ad43185978e24b65a9c7b538c392f4e2@o562868.ingest.sentry.io/6239418",
      integrations: [new Integrations.BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
  
  async _initFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyDNUX5_N8BCNkTAyrVdgvLkucCdJK9goxY",
      authDomain: "crested-drive-346208.firebaseapp.com",
      projectId: "crested-drive-346208",
      storageBucket: "crested-drive-346208.appspot.com",
      messagingSenderId: "956569212474",
      appId: "1:956569212474:web:cf04436fc3718435dec703",
      measurementId: "G-JTCY5240BV"
    };
    this.firebase = firebase.initializeApp(firebaseConfig);
    this.firestore = this.firebase.firestore();
    //if(__SNOWPACK_ENV__.NODE_ENV !== 'development')
    //  this.perf = firebase.performance();
    this.firestore.enablePersistence({synchronizeTabs:true/*,experimentalForceOwningTab:true*/}).catch(function(err) {
      if (err.code == 'failed-precondition') {
        console.log('Persistance: Failed Precondition');
      } else if (err.code == 'unimplemented') {
        console.log('Persistance: Unimplemented');
      }
    });
    this.storage = this.firebase.storage();
    this.firebase.auth().tenantId = this.activeOrg;
    this.firebase.auth().onAuthStateChanged(this.repositoryAuth.authStateChanged.bind(this.repositoryAuth));
  }

  async initLang(){
    let lang = window.localStorage.getItem('lang');
    if(!lang){
      window.localStorage.setItem('lang', 'en');
      lang = 'en';
    }
    store.dispatch(setLanguage(lang));
    registerTranslateConfig({
      loader: async lang => {
        let res = await (await fetch(`/assets/i18n/en.json`)).json();
        if(lang !== "en") {
          let resLang = await (await fetch(`/assets/i18n/${ lang }.json`)).json();
          res = _.merge(res, resLang);
        }
        return res;
      }
    });
    use(lang);
    //this.humanizeDuration = humanizeDuration.humanizer();
    /*humanizeDuration.humanizer({
      language: "shortEn",
      languages: {
        shortEn: {
          y: () => "y",
          mo: () => "mo",
          w: () => "w",
          d: () => "d",
          h: () => "h",
          m: () => "m",
          s: () => "s",
          ms: () => "ms",
        },
      }
    });*/
  }

  toggleLang(){
    let i = Object.keys(this.languages).indexOf(this.language) + 1;
    this.language = i < Object.keys(this.languages).length ? Object.keys(this.languages)[i] : Object.keys(this.languages)[0];
    use(this.language);
    window.localStorage.setItem('lang', this.language);
    store.dispatch(setLanguage(this.language));
  }

  /*** Auth ***/

  signOut(){
    this.repositoryAuth.signOut();
  }

  /****************
  *
  * Entities
  *
  ****************/

  getEntity({ urlBase, urlPath }={}){
    return fetch(`${urlBase}${urlPath}`, { method: "GET", headers: { 'Content-Type': this.defaultContentType, 'Authorization': 'Bearer ' + this.accessToken } });
  }

  saveEntity({ urlBase, urlPath, data }={}){
    return new Promise(function(resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', (urlBase ? urlBase : this.urlBase) + urlPath);
      xhr.setRequestHeader('Content-Type', this.defaultContentType);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.accessToken);
      xhr.onreadystatechange = function () {
        try{
          if (xhr.readyState === 4) {
            const response = JSON.parse(xhr.response);
            if(xhr.status === 200){
              const res = response.data;
              resolve(res);
            }else{
              const err = new Error(response.code);
              err.verbose = response.verbose;
              console.log(err.message, err.verbose);
              reject(err);
            }
          }
        }catch(err){
          reject(err);
        }
      };
      xhr.send(JSON.stringify(data));
      
    }.bind(this));
  }

  updateEntity({ urlBase, urlPath, data }={}){
    return new Promise(function(resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open('PATCH', (urlBase ? urlBase : this.urlBase) + urlPath);
      xhr.setRequestHeader('Content-Type', this.defaultContentType);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.accessToken);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.response);
          if(xhr.status === 200){
            const res = response.data;
            resolve(res);
          }else{
            const err = new Error(response.code);
            err.verbose = response.verbose;
            reject(err);
          }
        }
      };
      xhr.send(JSON.stringify(data));
    }.bind(this));
  }

  deleteEntity({ urlBase, urlPath }={}){
    return new Promise(function(resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open('DELETE', (urlBase ? urlBase : this.urlBase) + urlPath);
      xhr.setRequestHeader('Content-Type', this.defaultContentType);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.accessToken);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          let response;
          try {
            response = JSON.parse(xhr.response);
          }catch(err) {
            let parseError = new Error('parse-error');
            err.verbose = "Could not parse response";
            reject(err);
            return;
          }
          if(xhr.status === 200){
            const res = response.data;
            resolve(res);
          }else{
            const err = new Error(response.code);
            err.verbose = response.verbose;
            reject(err);
          }
        }
      };
      xhr.send(null);
    }.bind(this));
  }

  async createOrg( orgAlias, userAlias, email, password, needNewPass ) {
    const data = await this.saveEntity({ urlBase: this.urlBase, urlPath: `/orgs/${ this.activeOrg }/actions/createOrg`, data: { orgAlias, userAlias, email, password, needNewPass } });
  }
}

function _getRepository(){
  if(!window.__repository__)
    window.__repository__ = new Repository();
  return window.__repository__;
}

export const repository = _getRepository();