export const SET_LOADING = 'SET_LOADING';
export const GOT_LOGGED_USER = 'GOT_LOGGED_USER';
export const LOGGED_OUT = 'LOGGED_OUT';
export const GOT_ORG = 'GOT_ORG';
export const GOT_USERS = 'GOT_USERS';
export const GOT_USERS_SENSITIVE = 'GOT_USERS_SENSITIVE';
export const SET_E3KIT_STATUS = 'SET_E3KIT_STATUS';

export const setLoading = isLoading => {
  return {
    type: SET_LOADING,
    isLoading
  };
};

export const gotLoggedUser = user => {
  return {
    type: GOT_LOGGED_USER,
    user
  };
};

export const loggedOut = () => {
  return {
    type: LOGGED_OUT
  };
};

export const gotOrg = orgData => {
  return {
    type: GOT_ORG,
    orgData
  };
};

export const gotUsers = users => {
  return {
    type: GOT_USERS,
    users
  };
};

export const gotUsersSensitive = usersSensitive => {
  return {
    type: GOT_USERS_SENSITIVE,
    usersSensitive
  };
};

export const gotUser = user => {
  return {
    type: GOT_USER,
    user
  };
};

export const gotUserSensitive = userSensitive => {
  return {
    type: GOT_USER_SENSITIVE,
    userSensitive
  };
};

export const setE3KitStatus = status => {
  return {
    type: SET_E3KIT_STATUS,
    status
  };
};