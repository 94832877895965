import { css } from '../../_snowpack/pkg/lit-element.js';

export const QuicklyproStyles = 
  css`
    :host {
      display: block;
    }

    /* Workaround for IE11 displaying <main> as inline */
    main {
      display: block;
      overflow: auto;
      width: 100%;
      height: calc(100vh - 64px);
    }

    .page {
      display: none;
    }

    .page[active] {
      display: block;
    }
    
    mwc-drawer {
      --mdc-theme-surface: var(--app-background-light-color);
      --mdc-drawer-width: 13rem;
      background: var(--app-background-light-color);
    }

    mwc-top-app-bar-fixed {
      --mdc-theme-primary: var(--app-background-light-color);
      --mdc-top-app-bar-width: calc( 100vw - var(--mdc-drawer-width) );
      --mdc-theme-on-primary: var(--app-primary-text-color);
      --mdc-top-app-bar-fixed-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
    }

    .drawer-content {
      text-align: left;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 1rem;
      /*background: var(--app-background-color);*/
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }

    .drawer-logo {
      height: 3rem;
      margin-top: 0.5rem;
      width: calc(100% - 1rem);
      object-fit: contain;
    }

    .drawer-version {
      text-align: left;
      font-size: 0.7rem;
      margin-bottom: 2rem;
    }

    .drawer-content a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      column-gap: 0.5rem;
      padding: 0px;
      height: 2.5rem;
      font-size: 0.9rem;
      font-weight: 500;
      color: var(--app-secondary-text-color);
      transition: padding 0.2s, background-color 0.2s, color 0.2s;
      border-radius: 12px;
      background: none;
    }

    .menu-divider {
      height: 2rem;
    }

    .drawer-content a[selected] {
      /*border-left: 2px solid #fff;*/
      /*background-color: #383E4E;*/
      color: #fff;
      /*font-weight: bold;*/
      background: var(--app-accent-color);
      padding: 0 1rem;
    }

    .notification-pin {
      position: absolute;
      top: 0;
      right: calc(50% - 1.4rem);
      background-color: var(--app-accent-alt-color);
      width: 1.2rem;
      text-align: center;
      border-radius: 50%;
      height: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #dialogVideocall {
      --mdc-dialog-min-width: 80vw;
    }

    main component-splash {
      height: calc(100vh - 4rem);
    }

    /*** Mobile ***/

    @media (min-width: 1050px) {
      app-header,
      main,
      footer {
        margin-left: var(--app-drawer-width);
        width: calc(100% - var(--app-drawer-width));
      }

      .menu-btn {
        display: none;
      }

      .only-desktop {
        display: inline;
      }
    }

    @media (max-width: 1050px) {
      .toolbar-top {
        padding: 0 0.5rem;
      }

      mwc-top-app-bar-fixed {
        --mdc-top-app-bar-width: 100vw;
        /*--mdc-dialog-max-height: 95vh;*/
      }
    }
  `;