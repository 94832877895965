import {
  setRecordsLoading,
  gotRecords,
  setRecordLoading,
  gotRecord,
  setRecordSensitiveLoading,
  gotRecordSensitive,
  setUserRecordsLoading,
  gotUserRecords,
} from '../redux/actionsRecords.js';

import firebase from '../../_snowpack/pkg/firebase/app.js';
import '../../_snowpack/pkg/firebase/firestore.js';

import { store } from '../redux/store.js';

class RecordsRepository {
  constructor(repository){
    this.repository = repository;

    store.subscribe(this._stateChanged.bind(this));
    this._stateChanged();

    this.urlBase = "https://api.quicklypro.it";
    //this.urlBase = "https://8080-beige-hippopotamus-hdv2zskz.ws-eu32.gitpod.io";
    this.urlBaseAlg = "https://alg.quicklypro.it";
    //this.urlBaseAlg = "https://8080-crimson-junglefowl-waf0htdp.ws-eu18.gitpod.io";
    
    this.listenerRealtimeRecords = undefined;
    this.listenerRealtimeRecord = undefined;
    this.listenerRealtimeRecordSensitive = undefined;
    this.listenerRealtimeUserRecords = undefined;

    this.user = undefined;
  }

  async _stateChanged(state = store.getState()){
    this.user = state.auth.user;
  }

  async getRealtimeRecords(){
    if(this.listenerRealtimeRecords){
      this.listenerRealtimeRecords();
      this.listenerRealtimeRecords = undefined;
    }
    store.dispatch(setRecordsLoading(true));
    this.listenerRealtimeRecords = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).collection('records')/*.where("users", "array-contains", this.user.id)*/.orderBy("updateDate", "desc").onSnapshot(snapshot => {
      const records = [];
      snapshot.forEach(doc => {
        records.push({
          ...doc.data(),
          id: doc.id,
          initDate: doc.data().initDate.toDate(),
        });
      });
      store.dispatch(gotRecords(records));
      store.dispatch(setRecordsLoading(false));
    }, err => {
      console.log(err);
      store.dispatch(gotRecords(undefined));
      store.dispatch(setRecordsLoading(false));
    });
  }

  async getRealtimeRecord(recordId){
    if(this.listenerRealtimeRecord || recordId === undefined){
      this.listenerRealtimeRecord();
      this.listenerRealtimeRecord = undefined;
    }
    store.dispatch(setRecordLoading(true));
    this.listenerRealtimeRecord = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).collection('records').doc(recordId).onSnapshot(async doc => {
      let group = undefined;
      let recordData = doc.data();
      if(doc.exists && false) { //TODO Virgil not enabled
        try {
          /*//group = await this.repository.repositoryAuth.e3KitClient.loadGroup(sessionId, ownerCard);
          //await this.repository.repositoryAuth.e3KitClient.deleteGroup(sessionId);*/
          
          group = await this.repository.repositoryAuth.e3KitClient.getGroup(recordId);
          if(group === null) {
            //console.log('group is null, loading');
            const ownerCard = await this.repository.repositoryAuth.e3KitClient.findUsers(recordData.initFrom);
            group = await this.repository.repositoryAuth.e3KitClient.loadGroup(recordId, ownerCard);
          }
          if(recordData.initFrom === this.user.id) {
            const toBeAdded = [];
            const toBeRemoved = [];
            Object.entries(recordData.participants).map(([id, data]) => {
              if(group.participants.indexOf(id) < 0)
                toBeAdded.push(id);
            });
            group.participants.forEach(id => {
              if(!(id in recordData.participants))
                toBeRemoved.push(id);
            })
            //console.log(toBeAdded, toBeRemoved);
            const promises = [];
            if(toBeAdded.length > 0) {
              const newParticipants = await this.repository.repositoryAuth.e3KitClient.findUsers(toBeAdded);
              Object.entries(newParticipants).map(([id, data]) => {
                promises.push(group.add(data));
              });
            }
            if(toBeRemoved.length > 0) {
              const oldParticipants = await this.repository.repositoryAuth.e3KitClient.findUsers(toBeRemoved);
              Object.entries(oldParticipants).map(([id, data]) => {
                promises.push(group.remove(data));
              });
            }
            await Promise.all(promises);
          }
          //console.log('group loaded');
          
          await group.update();
          
          /*//await group.update();
          //console.log('group updated');

          //await this.repository.repositoryAuth.e3KitClient.deleteGroup(sessionId);
          //const participants = await this.repository.repositoryAuth.e3KitClient.findUsers(recordData.users);
          //const encryptedText = await this.repository.repositoryAuth.e3KitClient.authEncrypt('message message message message message', participants);
          //console.log(encryptedText);*/
        } catch(err) {
          //console.log(err);
          switch(err.name) {
            case "GroupError":
              console.log(err);
              if(this.user.id === recordData.initFrom) {
                //console.log("Blabla");
                const participants = await this.repository.repositoryAuth.e3KitClient.findUsers(recordData.users);
                group = await this.repository.repositoryAuth.e3KitClient.createGroup(recordId, participants);
              } else {
                console.log("No group"); // TODO should allow?
                store.dispatch(gotRecord(undefined, undefined));
                store.dispatch(setRecordLoading(false));
                return;
              }
              break;
            case "GroupTicketAlreadyExistsError":
              console.log("Group already exists");
              break;
            default: 
              console.log(err, err.status);
          }
        } 
      }
      store.dispatch(gotRecord(doc.exists ? { ...doc.data(), id: recordId, initDate: doc.data().initDate.toDate() } : undefined, group));
      store.dispatch(setRecordLoading(false));
    }, err => {
      console.log(err);
      store.dispatch(gotRecord(undefined, undefined));
      store.dispatch(setRecordLoading(false));
    });
  }

  async getRealtimeRecordSensitive(recordId){
    if(this.listenerRealtimeRecordSensitive || recordId === undefined){
      this.listenerRealtimeRecordSensitive();
      this.listenerRealtimeRecordSensitive = undefined;
    }
    store.dispatch(setRecordSensitiveLoading(true));
    this.listenerRealtimeRecordSensitive = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).collection('records').doc(recordId).collection('sensitive').doc(recordId).onSnapshot(async doc => {
      let group = undefined;
      store.dispatch(gotRecordSensitive(doc.exists ? { ...doc.data(), id: recordId } : undefined, group));
      store.dispatch(setRecordSensitiveLoading(false));
    }, err => {
      console.log(err);
      store.dispatch(gotRecordSensitive(undefined, undefined));
      store.dispatch(setRecordSensitiveLoading(false));
    });
  }

  async getRealtimeUserRecords(userId){
    if(this.listenerRealtimeUserRecords){
      this.listenerRealtimeUserRecords();
      this.listenerRealtimeUserRecords = undefined;
    }
    store.dispatch(setUserRecordsLoading(true));
    this.listenerRealtimeUserRecords = this.repository.firestore.collection('orgs').doc(this.repository.activeOrg).collection('records').where("initFrom", "==", userId).orderBy("updateDate", "desc").onSnapshot(snapshot => {
      const records = [];
      snapshot.forEach(doc => {
        records.push({
          ...doc.data(),
          id: doc.id,
          initDate: doc.data().initDate.toDate(),
        });
      });
      store.dispatch(gotUserRecords(records));
      store.dispatch(setUserRecordsLoading(false));
    }, err => {
      console.log(err);
      store.dispatch(gotUserRecords(undefined));
      store.dispatch(setUserRecordsLoading(false));
    });
  }

  async createRecord({ alias, cf, personal, settings }={}){
    return this.repository.saveEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/records/`, data: { alias, cf, personal, settings } });
  }

  async updateRecord({ recordId, data }={}){
    return this.repository.updateEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/records/${ recordId }/`, data });
  }

  async deleteRecord({ recordId }={}){
    return this.repository.deleteEntity({ urlBase: this.urlBase, urlPath: `/orgs/${this.repository.activeOrg}/records/${ recordId }/` });
  }

  async createSession(data) {
    return this.repository.saveEntity({ urlBase: this.urlBaseAlg, urlPath: `/`, data });
  }
}

export default RecordsRepository;