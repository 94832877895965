import { html, css, LitElement } from '../../_snowpack/pkg/lit-element.js';

import { SharedStyles } from '../extras/shared-styles.js';

import { repository } from '../network/repository.js';

import firebase from '../../_snowpack/pkg/firebase/app.js';
import '../../_snowpack/pkg/firebase/auth.js';

import '../components/component-splash.js';
import '../../_snowpack/pkg/@material/mwc-button.js';
import '../../_snowpack/pkg/@material/mwc-textfield.js';

class ComponentResetPass extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
          flex-direction: column;
        }

        .reset-pass-form {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 280px;
          max-width: calc(100vw - 4rem - 6px);
          padding: 1rem 2rem 2rem 2rem;
          border-radius: 0.5rem;
          background: #fff;
          box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
          font-family: 'Montserrat', sans-serif;
        }

        .reset-pass-form mwc-textfield {
          width: 100%;
          margin-bottom: 1rem;
        }

        .reset-pass-form mwc-button {
          --mdc-theme-primary: #fff;
          --mdc-theme-on-primary: #000;
        }

        .reset-pass-form .reset-pass-button {
          --mdc-theme-primary: var(--app-accent-color);
          --mdc-theme-on-primary: #fff;
        }

        .errors {
          margin-top: 1rem;
          color: tomato;
          display: flex;
          flex-direction: column;
          row-gap: 0.5rem;
        }

        .errors span {
          text-decoration: underline;
          color: #fff;
          cursor: pointer;
        }

        component-splash {
          background-color: rgba(255,255,255,0.3);
          position: absolute;
          top: 0; right: 0; bottom: 0; left: 0;
          z-index: 9;
        }
      `
    ];
  }

  render() {
    return html`
      <div class="reset-pass-form">
        <p style="margin-bottom: 0;">Welcome <b>${ this.user.email }</b></p>
        <p style="margin-top: 0.5rem;">Type a new password please</p>
        <mwc-textfield label="New Password" type="password" name="pwd" required @keyup=${this._checkEnter} minlength="6" ?disabled=${this._isResettingPass}></mwc-textfield>
        <mwc-button class="reset-pass-button" @click=${this._resetPass} raised ?disabled=${this._isResettingPass}>Save</mwc-button>
        ${!this._isResettingPass && this._hasErrors !== undefined
          ? html`
            <div class="errors">
              ${this._hasErrors.message}
            </div>
          ` : html`
        `}
        ${ this._isResettingPass
          ? html`
            <component-splash class="embedded"></component-splash>
          ` : html``
        }
      </div>
      <p style="font-weight: bold; cursor: pointer;" @click=${ e => { repository.signOut() } }><b>Sign out</b></p>
    `
  }

  static get properties() {
    return {
      user: { type: Object },
      _isResettingPass: { type: Boolean },
      _hasErrors: { type: Object },
    };
  }

  constructor(){
    super();
    this._isResettingPass = false;
    this._hasErrors = undefined;
  }

  async _resetPass(){
    this._hasErrors = undefined;
    const inputPwd = this.shadowRoot.querySelector("mwc-textfield[name=pwd]");
    if(!inputPwd.reportValidity())
      return;
    this._isResettingPass = true;
    try {
      await repository.repositoryAuth.resetPassword(inputPwd.value);
      //await __repository__.firebase.auth().signInWithEmailAndPassword(inputEmail.value, inputPwd.value);
      //await new Promise(r => setTimeout(r, 5000));
      repository.firebase.auth().signInWithEmailAndPassword(this.user.email, inputPwd.value)
      this._isResettingPass = false;
    } catch(err) {
      switch(err.code){
        case "auth/wrong-password":
          this._hasErrors = { message: "Wrong email/password", code: err.code };
          break;
        case "auth/user-not-found":
          this._hasErrors = { message: "User not found", code: err.code };
          break;
        case "auth/too-many-requests":
          this._hasErrors = { message: "Too many requests, reset your password or try again later", code: err.code };
          break;
        default:
          this._hasErrors = { message: "Unable to login", code: "unknown" };
          console.log(err);
      }
      this._isResettingPass = false;
    }
  }

  _checkEnter(e) {
    if (e.keyCode === 13)
      this._resetPass();
  }
}

window.customElements.define('component-reset-pass', ComponentResetPass);