import { LitElement, html, css } from '../../_snowpack/pkg/lit-element.js';

import { repository } from '../network/repository.js';

import { connect } from '../../_snowpack/pkg/pwa-helpers.js';
import { store } from '../redux/store.js';

import {setPassiveTouchGestures} from '../../_snowpack/pkg/@polymer/polymer/lib/utils/settings.js';

import { SharedStyles } from '../extras/shared-styles.js';
import { QuicklyproStyles } from './quicklypro-styles.js';

import '../components/component-splash.js';
import '../auth/component-auth.js';
import '../auth/component-reset-pass.js';
import '../auth/component-landing.js';
import './quicklypro-app.js';

class ModuleQuicklypro extends connect(store)(LitElement) {
  static get styles() {
    return [
      SharedStyles,
      QuicklyproStyles,
      css`
        .loader {
          height: 100vh;
        }

        .loader component-splash {
          --blob-color: #fff;
        }
      `
    ];
  }

  static get properties() { 
    return {
      isLoading: { type: Boolean },
      user: { type: Object },
      org: { type: Object },
    }
  }

  async stateChanged(state) {
    this.isLoading = state.auth.isLoading;
    this.user = state.auth.user;
    this.org = state.auth.org;
  }

  constructor(){
    super();
    setPassiveTouchGestures(true);
  }

  render() {
    return html`
      ${ (!!window.chrome && /*(!!window.chrome.webstore || !!window.chrome.runtime) &&*/ !(navigator.userAgent.indexOf("Edg") != -1)) || ( navigator.userAgent.match('CriOS') ) || (typeof InstallTrigger !== 'undefined')
        ? html`
          ${ this.isLoading
            ? html`
              <component-landing class="loader"><component-splash><small>Loading...</small></component-splash></component-landing>
            ` : html``
          }
          ${ !this.isLoading && this.user
            ? html`
              ${ this.user.isGuest
                ? html`
                  <component-landing>
                    <p>Welcome <b>${ this.user.email }</b></p>
                    <p>You're not part of any organization, contact your administrator...</p>
                    <p style="font-weight: bold; cursor: pointer;" @click=${ e => { repository.signOut() } }><b>Sign out</b></p>
                  </component-landing>
                ` : html`
                  ${ this.user.sensitive.needNewPass
                    ? html`
                      <component-landing>
                        <component-reset-pass .user=${ this.user }></component-reset-pass>
                      </component-landing>
                    ` : html`
                      ${ this.org === undefined
                        ? html`
                          <component-landing class="loader">
                            <component-splash>
                              <small>${ !this.org ? "Getting organization info..." : "Loading..." }</small>
                            </component-splash>
                          </component-landing>
                        ` : html`
                          <quicklypro-app></quicklypro-app>
                        `
                      }
                    `
                  }
                `
              }
            ` : html``
          }
          ${ !this.isLoading && !this.user 
            ? html`
              <component-landing><component-auth></component-auth></component-landing>
            ` : html``
          } 
        ` : html`
          <component-landing>
            <h3>Your browser is not supported</h3>
            <p>Please use one of the following:</p>
            <div style="display: flex; justify-content: center;">
              <a href="https://www.google.com/chrome/" target="_blank" style="display: flex;align-items: center;flex-direction: column;"><img src="/images/chrome.png"><small style="font-size: 0.6rem;">*Preferred</small></a>
              <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank"><img src="/images/firefox.png"></a>
            </div>
          </component-landing>
        `
      }
    `;
  }
}

customElements.define('module-quicklypro', ModuleQuicklypro);