import { combineReducers, createStore } from '../../_snowpack/pkg/redux.js';
import { reducer as reducerCore } from './reducerCore.js';
import { reducer as reducerAuth } from './reducerAuth.js';
import { reducer as reducerRecords } from './reducerRecords.js';
import { reducer as reducerSessions } from './reducerSessions.js';
import { devToolsEnhancer } from '../../_snowpack/pkg/redux-devtools-extension/logOnlyInProduction.js';

const STORAGE_KEY = '__quicklypro_app__';

const saveState = state => {
  /*localStorage.setItem(STORAGE_KEY, JSON.stringify(state));*/
};

const loadState = () => {
  const json = localStorage.getItem(STORAGE_KEY);
  if(!json)
    return undefined;
  const state = JSON.parse(json);
  return state;
};

const reducer = combineReducers({
  core: reducerCore,
  auth: reducerAuth,
  records: reducerRecords,
  sessions: reducerSessions
})

export const store = createStore(
  reducer,
  /*loadState(), // To load previous */
  devToolsEnhancer()
);

store.subscribe(() => { // To save to storage
  saveState(store.getState());
});