import {
  SET_RECORDS_LOADING,
  GOT_RECORDS,
  SET_RECORD_LOADING,
  GOT_RECORD,
  SET_RECORD_SENSITIVE_LOADING,
  GOT_RECORD_SENSITIVE,
  SET_USER_RECORDS_LOADING,
  GOT_USER_RECORDS,
} from './actionsRecords.js';

const INITIAL_STATE = {
  isRecordsLoading: true,
  records: undefined,
  isRecordLoading: true,
  record: undefined,
  isRecordSensitiveLoading: true,
  recordSensitive: undefined,
  e3group: undefined,
  isUserRecordsLoading: true,
  userRecords: undefined,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RECORDS_LOADING:
      return {
        ...state,
        isRecordsLoading: action.isLoading
      };
    case GOT_RECORDS:
      return {
        ...state,
        records: action.records
      };
    case SET_RECORD_LOADING:
      return {
        ...state,
        isRecordLoading: action.isLoading
      };
    case GOT_RECORD:
      return {
        ...state,
        record: action.record,
        e3group: action.e3group
      };
    case SET_RECORD_SENSITIVE_LOADING:
      return {
        ...state,
        isRecordSensitiveLoading: action.isLoading
      };
    case GOT_RECORD_SENSITIVE:
      return {
        ...state,
        recordSensitive: action.recordSensitive,
      };
    case SET_USER_RECORDS_LOADING:
      return {
        ...state,
        isUserRecordsLoading: action.isLoading
      };
    case GOT_USER_RECORDS:
      return {
        ...state,
        userRecords: action.records
      };
    default:
      return state
  }
};