import {
  SET_LOADING,
  GOT_LOGGED_USER,
  LOGGED_OUT,
  GOT_ORG,
  GOT_USERS,
  GOT_USERS_SENSITIVE,
  SET_E3KIT_STATUS,
} from './actionsAuth.js';

const INITIAL_STATE = {
  isLoading: false,
  user: undefined,
  org: undefined,
  users: undefined,
  usersSensitive: undefined,
  e3KitStatus: 'loading',
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case GOT_LOGGED_USER:
      return {
        ...state,
        user: action.user,
      };
    case LOGGED_OUT:
      return {
        ...state,
        user: undefined,
        userSensitive: undefined,
      };
    case GOT_ORG:
      return {
        ...state,
        org: action.orgData
      };
    case GOT_USERS:
      return {
        ...state,
        users: action.users
      };
    case GOT_USERS_SENSITIVE:
      return {
        ...state,
        usersSensitive: action.usersSensitive
      };
    case SET_E3KIT_STATUS:
      return {
        ...state,
        e3KitStatus: action.status
      };
    default:
      return state
  }
};