import { LitElement, html, css } from '../../_snowpack/pkg/lit-element.js';

import { translate } from "../../_snowpack/pkg/@appnest/lit-translate.js";

import { repository } from '../network/repository.js';

import { connect } from '../../_snowpack/pkg/pwa-helpers.js';
import { store } from '../redux/store.js';

import { installRouter } from '../../_snowpack/pkg/pwa-helpers/router.js';
import { installMediaQueryWatcher } from '../../_snowpack/pkg/pwa-helpers/media-query.js';

import { SharedStyles } from '../extras/shared-styles.js';
import { QuicklyproStyles } from './quicklypro-styles.js';

import '../../_snowpack/pkg/@material/mwc-top-app-bar-fixed.js';
import '../../_snowpack/pkg/@material/mwc-icon-button.js';
import '../../_snowpack/pkg/@material/mwc-icon.js';
import '../../_snowpack/pkg/@material/mwc-drawer.js';
//import '/dist/components/component-user-info.js';
//import '/dist/components/component-splash.js';
//import '/dist/components/page-no-permissions.js';

//import { Utils } from '/dist/extras/utils.js';

class QuicklyproApp extends connect(store)(LitElement) {
  static get styles() {
    return [
      SharedStyles,
      QuicklyproStyles
    ];
  }

  render() {
    return html`
      <mwc-drawer type="${this._wideLayout ? "dismissible" : "modal"}" ?open=${this._drawerOpened} @MDCDrawer:opened=${ e => { this._updateDrawerState(true) } } @MDCDrawer:closed=${ e => { this._updateDrawerState(false) } }>
        <div class="drawer-content">
          <div>
            <img class="drawer-logo" src="${ '/images/logo_full_color.png'}">
            <div class="drawer-version">${ this.version } - Beta</div>
            <a ?selected="${this._page === 'dashboard'}" href="/dashboard"><mwc-icon>dashboard</mwc-icon>${ translate("menu.dashboard") }</a>
            <div class="menu-divider"></div>
            <a ?selected="${this._page === 'records'}" href="/records"><mwc-icon>health_and_safety</mwc-icon>${ translate("menu.records") }</a>
            ${ this.user && this.user.claims.t === 'r'
              ? html`
                <a ?selected="${this._page === 'users'}" href="/users"><mwc-icon>supervisor_account</mwc-icon>${ translate("menu.users") }</a>
              ` : html``
            }
          </div>
          <div class="bottom-menu-items">
            <div class="menu-divider"></div>
            <a href="#" @click="${ e => { e.preventDefault(); repository.toggleLang(); } }"><mwc-icon>language</mwc-icon>${translate(`menu.lang-${this.language}`)}</a>
            <a href="#" @click="${ e => { e.preventDefault(); repository.signOut(); } }">
              <mwc-icon>exit_to_app</mwc-icon>
              ${ translate("menu.logout") }
            </a>
          </div>
        </div>
        <div slot="appContent">
          <mwc-top-app-bar-fixed .scrollTarget=${ this.shadowRoot.querySelector('main') }>
            ${ !this._wideLayout ? html`
                <mwc-icon-button slot="navigationIcon" icon="menu" @click=${e => { this._updateDrawerState(!this._drawerOpened) }}></mwc-icon-button>
              ` : html``
            }
            <div slot="title">Demo</div>
            <main role="main" class="main-content">
              <module-dashboard class="page" ?active="${this._page === 'dashboard'}"><component-splash class="embedded"></component-splash></module-dashboard>
              <module-records class="page" ?active="${this._page === 'records'}"><component-splash class="embedded"></component-splash></module-records>
              <module-record class="page" ?active="${this._page === 'record'}" .recordId=${ this._pageId }><component-splash class="embedded"></component-splash></module-record>
              <module-user class="page" ?active="${this._page === 'user'}" .userId=${ this._pageId }><component-splash class="embedded"></component-splash></module-user>
              ${ this.user && this.user.claims.t === 'r' ? html`
                <module-users class="page" ?active="${this._page === 'users'}"><component-splash class="embedded"></component-splash></module-users>
              ` : html`
                <page-no-permissions class="page" ?active="${this._page === 'groups' || this._page === 'users'}"><component-splash class="embedded"></component-splash></page-no-permissions>
              ` }
              <page-404 class="page" ?active="${this._page === 'page404'}"><component-splash class="embedded"></component-splash></page-404>
            </main>
          </mwc-top-app-bar-fixed>
        </div>
      </mwc-drawer>
    `;
  }

  static get properties() { 
    return {
      user: { type: Object },
      details: { type: Object },
      language: { type: String },
      version: { type: String },

      _pageId: { type: String },
      _page: { type: String },
      _drawerOpened: { type: Boolean },
      _wideLayout: { type: Boolean },
    }
  }

  constructor() {
    super();
    this._pageId = '';
    this._page = '';
    this._drawerOpened = false;
    this._wideLayout = false;

    window.addEventListener('location-changed', function (e) {
      this._locationChanged(window.location);
    }.bind(this));
  }

  async stateChanged(state) {
    this.user = state.auth.user;
    this.details = state.auth.details;
    this.language = state.core.language;
    this.version = state.core.version;
  }

  firstUpdated() {
    installRouter((location) => this._locationChanged(location));
    installMediaQueryWatcher(`(min-width: 1050px)`, (matches) => this._layoutChanged(matches));
    repository.repositoryVideocall.refDialog = this.shadowRoot.querySelector("#dialogVideocall");
  }

  _locationChanged(location) {
    const path = window.decodeURIComponent(location.pathname);
    const splittedPath = path.split('/');

    let page = splittedPath[1] || 'dashboard';

    if(page === 'record' || page === 'session' || page === 'user'){
      this._pageId = splittedPath[2];
    }else{
      this._pageId = '';
    }
    this._loadPage(page);

    if(!this._wideLayout)
      this._updateDrawerState(false);
  }

  async _loadPage(page) {
    switch(page) {
      case 'records':
        import("../records/module-records.js");
        break;
      case 'record':
        import("../records/module-record.js");
        break;
      case 'user':
        import("../users/module-user.js");
        break;
      case 'users':
        import("../users/module-users.js");
        break;
      case 'dashboard':
        import("../dashboard/module-dashboard.js");
        break;
      default:
        //import('../components/page-404.js');
        this._page = "page404";
        return;
    }
    this._page = page;
  }

  async _checkUserPage(){
    if(this.user && !this._page)
      this._locationChanged(window.location);
  }

  _updateDrawerState(opened) {
    if (opened !== this._drawerOpened) {
      this._drawerOpened = opened;
    }
  }

  _drawerOpenedChanged(e) {
    this._updateDrawerState(e.target.opened);
  }

  _layoutChanged(isWideLayout) {
    if (isWideLayout !== this._wideLayout) {
      this._wideLayout = isWideLayout;
      if(isWideLayout && !this._drawerOpened)
        this._drawerOpened = true;
      if(!isWideLayout && this._drawerOpened)
        this._drawerOpened = false;
    }
  }
}

customElements.define('quicklypro-app', QuicklyproApp);