import { html, css, LitElement } from '../../_snowpack/pkg/lit-element.js';

import { SharedStyles } from '../extras/shared-styles.js';

import { connect } from '../../_snowpack/pkg/pwa-helpers.js';
import { store } from '../redux/store.js';

//import './component-back-anim.js';
import '../components/loader-1.js';

class ComponentLanding extends connect(store)(LitElement) {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
          height: 100%;
          position: relative;
        }

        .wrapper {
          width: 100%;
          height: 100%;
          background: var(--app-background-alt-color);
          background: linear-gradient(-45deg, var(--app-accent-color), #2ee8c8);
          background-size: 200% 200%;
          animation: gradient 15s ease infinite;
        }

        .version {
          position: fixed;
          bottom: 0.5rem;
          right: 0.5rem;
          z-index: 2;
        }

        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      `
    ];
  }

  render() {
    return html`
      <div class="wrapper">
        <slot></slot>
      </div>
      <div class="version"><small>${this.version}</small></div>
    `
  }

  static get properties() {
    return {
      version: { type: String },
    };
  }
  
  async stateChanged(state) {
    this.version = state.core.version;
  }
}

window.customElements.define('component-landing', ComponentLanding);